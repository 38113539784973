
import { ResetPassword } from "@/services/auth.service";
import { Options, setup, Vue } from "vue-class-component";
import { useToast } from "vue-toastification";

@Options({
    data() {
        return {
            loginFormData: {
                email: "",
            },
        };
    },
    components: {},
    methods: {
        onSubmit() {
            ResetPassword(this.loginFormData.email)
                .then((resp: any) => {
                    this.toast.success(
                        resp.data.message || "Password reset link emailed."
                    );
                    this.$router.push("/");
                })
                .catch(() => {
                    this.toast.error(
                        "Failed to complete your request, please check your email and try again."
                    );
                });
        },
    },
})
export default class LogIn extends Vue {
    toast = setup(() => useToast());
}
